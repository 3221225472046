import React from "react";
import DividerShadow from "./DividerShadow";
import HeroSection from "./HeroSection";
import IflcSection from "./IflcSection";
import NewsBanner from "./NewsBanner";
import ProLearning from "./ProLearning";
import YouDebate from "./YouDebate";

const Homepage = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <HeroSection />
      <ProLearning />
      <DividerShadow />
      <br />
      <IflcSection />
      <DividerShadow />
      <YouDebate />
      <br />
      <NewsBanner />
    </div>
  );
};

export default Homepage;
