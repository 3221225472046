import { List, Skeleton, Switch, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../resources/AdminService";
const Contacts = () => {
  // var moment = require("moment-timezone");
  const [initialRender, setInitialRender] = useState(true);
  const [contacts, setContacts] = useState(null);
  const [initLoading, setInitLoading] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getContacts().then((data) => {
        setContacts(data.data);
        setInitLoading(false);
      });
    }
  }, [initialRender]);

  const okChange = (id) => {
    AdminService.changeIsOk(id).then((data) => {
      setContacts(data.data.value);
    });
  };
  return (
    <div>
      <h2 className="text-3xl text-blue-500 font-extrabold">Contacts</h2>
      {contacts && (
        <div className="m-1 border  shadow-sm max-w-5xl">
          <List
            className="demo-loadmore-list "
            loading={initLoading}
            itemLayout="horizontal"
            dataSource={contacts}
            renderItem={(item, index) => (
              <List.Item
                style={{
                  backgroundColor: index % 2 === 0 ? "white" : "#f5f5f5",
                  paddingLeft: "10px",
                }}
                actions={[
                  <Switch
                    checked={item.isOk}
                    onChange={() => okChange(item.id)}
                  />,
                ]}
              >
                <Skeleton title={false} loading={initLoading} active>
                  <List.Item.Meta
                    title={
                      <div>
                        <b className="text-blue-500">
                          {item.name} / {item.state} /{" "}
                          <Tag>
                            {moment(item.createdOn).format(
                              "DD-MM-YY ddd HH:mm"
                            )}
                          </Tag>
                        </b>
                        <br />
                        <br />
                        {item.subject}
                      </div>
                    }
                    description={
                      <div className="text-gray-700">
                        {item.message}
                        <br />
                        <br />
                        <span>
                          Reply :&nbsp;
                          <a href={`mailto://${item.email}`}>
                            <Tag color="blue">{item.email}</Tag>
                          </a>
                        </span>
                      </div>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Contacts;
