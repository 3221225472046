import React from "react";

const OrgChart = () => {
  return (
    <div className="container mx-auto max-w-7xl">
      <img
        src={require("../../assets/images/chart.jpg").default}
        width="100%"
        alt="Organisational Chart"
      />
    </div>
  );
};

export default OrgChart;
