import { Button, Space } from "antd";
import React from "react";
import ReactPlayer from "react-player";

const Iflc = () => {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-100 absolute top-0 bottom-0 left-3/4 w-screen border-l" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              INTERNATIONAL FESTIVAL OF LANGUAGE AND CULTURE
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              IFLC Australia
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-300"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={require("../../assets/images/iflc-aus.jpg").default}
                    alt="about"
                    width="100%"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-4 lg:-mt-2 hakkinda">
            <div className="text-base max-w-prose mx-auto lg:max-w-none text-gray-600">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=IfnNXjAmFy4"
                playing
                width="100%"
                controls
              />
              <br />
              <p>
                <b>International Festival of Language and Culture (‘IFLC’) </b>
                is an annual celebration that showcases the diversity of
                linguistic talents from across the globe, with the sole vision
                of uniting nations of the world through universal human values
                and also promoting inclusiveness. It’s the living embodiment of
                respect, harmony and peace that can be achieved with the
                universal language of art, dance and music – colours of the
                world.
              </p>
              <p>
                The celebration promotes cultural and linguistic diversity and
                the values of universal peace, love and friendship through the
                medium of song and dance.
              </p>
              <p>
                <b>IFLC </b> started in 2003 in Turkey with students from 17
                countries. In 2015, 160 countries took part in the events across
                the world with more than 2000 participants. Since 2015{" "}
                <b>IFLC </b>
                franchise is also held in Australia. This event provides
                participating students great opportunities to present aspects of
                their language and culture with other students from around the
                world and to local audiences in host countries. Lifelong
                friendships are gained, with bridges formed to cross the
                promoted Islands of Peace.
              </p>
              <p>
                In 2015 this spectacular festival was performed in Australia for
                the first time, with our member Sirius College’s organisation at
                Melbourne Convention Centre. Thereon, the festival has been
                hosted between Melbourne and our Sydney members Amity College
                and Affinity Intercultural Foundation.
              </p>
              <p>
                The <b>IFLC </b> is currently held in more than 20 different
                countries and attracts more than 2000 participants from more
                than 145 countries. The colourful showcase of culture and
                language through the medium of performing arts, creates a
                positive synergy on and off stage; promoting long-lasting
                friendships between the participants and instills goodwill
                partnerships for future projects.
              </p>
              <p>
                In 2019, the global IFLC franchise will celebrate its 17th year
                and it will be the 5th <b>IFLC </b> in Australia. Sirius
                College, in partnership with Australian Intercultural Society
                will be co-hosting the 2019 International Language and Culture
                Festival on Saturday, 26th October 2019 at Melbourne Convention
                and Exhibition Centre (MCEC).
              </p>
              <p>
                The <b>IFLC </b> Australia has been staged in several iconic
                venues, including the Melbourne Convention and Exhibition Centre
                in 2015 and 2017, in which it attracted more than 5000 people,
                and the Sydney Opera House in 2016 and 2018 with more than 2300
                people attending with many dignitaries including the Governor
                General of Australia Sir Peter Cosgrove and Governor of NSW Sir
                David Hurley. With more than 280 students from 40 different
                countries having performed so far, <b>IFLC </b> promises to
                cement Australia’s position as the premier country to host the{" "}
                <b>IFLC </b> and, in doing so, embrace and celebrate
                inclusiveness and diversity.
              </p>
              <p>
                Since the first <b>IFLC </b> in Melbourne in 2015, <b>IFLC </b>{" "}
                has been hosted by the very talented TV personality George
                Donikian. The IFLC Melbourne programs in 2015 and 2017 and the{" "}
                <b>IFLC </b> Sydney programs in 2016 & 2018 were nationally
                broadcasted on SBS One.
              </p>
              <Space direction="vertical" style={{ width: "100%" }}>
                <span>IFLC links:</span>
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={() =>
                    window.open("https://www.iflc.org.au/", "_blank")
                  }
                >
                  IFLC Australia microsite
                </Button>
                <Button
                  type="primary"
                  block
                  danger
                  size="large"
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UC1SG8pOoHQdg85llPsBXlcQ",
                      "_blank"
                    )
                  }
                >
                  IFLC Australia YouTube Channel
                </Button>
                <Button
                  type="primary"
                  block
                  size="large"
                  onClick={() => window.open("https://intflc.org/", "_blank")}
                >
                  IFLC Global Site
                </Button>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Iflc;
