import { axios } from "./HttpClient";

export const AdminService = {
  newNewsletter: (values) => {
    return axios.post("/newsletters", { ...values });
  },
  newContact: (values) => {
    return axios.post("/contacts", { ...values });
  },
  getContacts: () => {
    return axios.get("/contacts");
  },
  changeIsOk: (id) => {
    return axios.put("/contacts/isok/" + id);
  },
  getNewsletters: () => {
    return axios.get("/newsletters");
  },
  newNews: (values) => {
    return axios.post("/news", { ...values });
  },
  getNews: () => {
    return axios.get("/news");
  },
  getNewsHome: () => {
    return axios.get("/news/home");
  },
  deleteNews: (id) => {
    return axios.delete("/news/" + id);
  },
  changeNewsFavorite: (id) => {
    return axios.put("/news/favorite/" + id);
  },
  changeNewsActive: (id) => {
    return axios.put("/news/active/" + id);
  },
  getDashboard: () => {
    return axios.get("/dbset/dashboard");
  },
  login: (values) => {
    return axios.post("/staff/login", { ...values });
  },
  getFavoriteNews: () => {
    return axios.get("/news/favorite");
  },
};
