import React from "react";

const Objectives = () => {
  return (
    <div>
      <ol>
        <li>
          To promote and protect the separate and collective interests of
          Members.
        </li>
        <li>
          To provide professional leadership, services and resources for the
          Members.
        </li>
        <li>
          To provide professional advice to the Members in industrial and legal
          matters.
        </li>
        <li>
          To improve the industrial and statutory rights and benefits of
          Members.
        </li>
        <li>
          To act on behalf of, in the interests of, and in conjunction, with the
          Members at all times.
        </li>
        <li>
          To advocate for and represent the interests and needs of Members.
        </li>
        <li>
          To liaise with state and federal governments and other peak bodies to
          represent the views and needs of the Members.
        </li>
        <li>
          To provide a range of high quality professional services and support
          to Members in a range of areas, including but not limited to;
          <ul>
            <li>employment and recruitment;</li>
            <li>professional development;</li>
            <li>government policy and regulations;</li>
            <li>school governance, evaluation and review;</li>
            <li>federal grants.</li>
          </ul>
        </li>
        <li>
          To affiliate, amalgamate, enter into any agreement with or otherwise
          co-operate with or assist any other state-wide, national and
          international Organisation, Association, Institution or Group in
          pursuit of these objects.
        </li>
        <li>
          To act as an agent for and on behalf of Members and non-members when
          requested in relation to employment matters.
        </li>
        <li>
          To promote the Australian federal and state governments’ relevant
          policies to Members.
        </li>
        <li>
          To oversee the general operations of the Members and suggest
          recommendations to improve quality.
        </li>
        <li>
          To develop plans and strategies to improve efficiency and productivity
          of the Members.
        </li>
        <li>
          To apply the funds, property and capacity of the Federation towards
          the fulfilment and achievement of the objects.
        </li>
        <li>
          To act as an agent for and on behalf of Members and non-members in a
          manner consistent with these objects and the Rules and in the
          interests of Members and to do all things necessary and incidental
          thereto.
        </li>
        <li>
          To do any things incidental to or conductive to the carrying out of
          any of the objects as are necessary, expedient, desirable or
          advisable.
        </li>
        <li>
          None of these objects is to be read in such a way as to limit any
          other of these objects.
        </li>
      </ol>
    </div>
  );
};

export default Objectives;
