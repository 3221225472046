import React from "react";

const DividerShadow = () => {
  return (
    <div
      className=" text-center flex "
      style={{ width: "100%", justifyContent: "center" }}
    >
      <img
        src={require("../../assets/images/divider.png").default}
        alt="divider"
      />
    </div>
  );
};

export default DividerShadow;
