import { Col, Row, Statistic } from "antd";
import React from "react";
import Objectives from "./Objectives";

export default function AboutTop() {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-100 absolute top-0 bottom-0 left-3/4 w-screen border-l" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              Australian Universal Federation of Education and Culture
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              About AUF
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-300"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)"
              />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="rounded-lg shadow-lg object-cover object-center"
                    src={require("../../assets/images/about.jpg").default}
                    alt="about"
                    width="100%"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-4 lg:-mt-2 hakkinda">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              <p className="text-lg text-gray-500">
                AUF was founded in 2012 by the following foundations in 5
                different states in order to advance cooperation and
                professional development activities between its members.
              </p>
            </div>
            <div className="mt-3  text-gray-700 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 ">
              <p>AUF founding members :</p>
              <ul>
                <li>Baris Education & Culture Foundation Ltd, WA </li>
                <li>Galaxy Foundation Ltd, NSW </li>
                <li>Multicultural Youth Education Support Services Ltd, SA </li>
                <li>Queensland Education & Cultural Foundation Ltd, QLD </li>
                <li>Selimiye Foundation Ltd, VIC </li>
              </ul>
            </div>
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              MISSION
            </h2>
            <p>
              Australian Universal Federation of Education and Culture (AUF)’s
              mission is to provide professional advice, services and leadership
              for its members.
            </p>
            <p>
              Our purpose is to provide a range of high quality professional
              services and support to our members in a range of areas, including
              but not limited to;
            </p>
            <ul>
              <li>employment and recruitment;</li>
              <li>professional development;</li>
              <li>government policy and regulations;</li>
              <li>school governance, evaluation and review;</li>
              <li>federal grants and more</li>
            </ul>
            <p>
              We advocate for the interests of, and in conjunction with our
              Members at all times.
            </p>
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              VISION
            </h2>
            <p>
              Australian Universal Federation of Education and Culture’s (AUF)
              vision is to be an essential national non-profit organisation that
              is a source of innovative professional solutions for its members
              which enables them to make effective contributions to wider
              Australian society in terms promoting social cohesion and mutual
              respect through advancing education and culture.
            </p>
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              Objectives of AUF
            </h2>
            <Objectives />
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              Values Statement
            </h2>
            <p>
              AUF is a non-profit organisation governed on principles of
              honesty, transparency and respect. AUF is committed to the highest
              standards of ethical behaviour in its interaction with its members
              and other organisations.
            </p>
            <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">
              AUF BOARD
            </h2>
            <p>
              AUF Board Consist of the representatives of our members and the
              following executive officers.
            </p>
            <Row gutter={16}>
              <Col span={12}>
                <Statistic
                  title="AUF Chairman"
                  value="Mr Ismail Aydogan"
                  valueStyle={{ fontSize: "18px" }}
                />
              </Col>
              <Col span={12}>
                <Statistic
                  title="Secretary"
                  value="Mr M.Samet Kayacik"
                  valueStyle={{ fontSize: "18px" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}
