import { LockOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const MainMenu = ({ sic }) => {
  const { SubMenu } = Menu;
  const location = useLocation();

  const { lg } = useBreakpoint();
  return (
    <div className="flex flex-1">
      <Menu
        onSelect={() => sic(false)}
        mode={lg ? "horizontal" : "inline"}
        defaultSelectedKeys={[location.pathname.split("/").pop()]}
        style={{ width: "670px" }}
      >
        <Menu.Item key="homepage">
          <Link to="/home/homepage">HOME</Link>
        </Menu.Item>
        <SubMenu key="about" title="ABOUT">
          <Menu.Item key="aboutus">
            <Link to="/home/about">About AUF</Link>
          </Menu.Item>
          <Menu.Item key="chart">
            <Link to="/home/chart">Organisational Chart</Link>
          </Menu.Item>
          <Menu.Item key="reports">
            <Link to="/home/reports">Annual Reports</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="members">
          <Link to="/home/members">MEMBERS</Link>
        </Menu.Item>
        <SubMenu key="events" title="EVENTS">
          <Menu.Item key="iflc">
            <Link to="/home/iflc">IFLC</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="news">
          <Link to="/home/news">NEWS</Link>
        </Menu.Item>
        <Menu.Item key="contact">
          <Link to="/home/contact">CONTACT</Link>
        </Menu.Item>
        <Menu.Item key="login">
          <Link to="/login">
            {localStorage.getItem("user") ? (
              <span className="text-red-700">LOGOUT</span>
            ) : (
              <LockOutlined style={{ color: "#187A53" }} />
            )}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainMenu;
