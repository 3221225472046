import { Card, Col, Row, Radio, Descriptions, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Companies } from "../../resources/companies";

const Members = () => {
  const [radio, setRadio] = useState(3);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeCompany, setActiveCompany] = useState(null);
  const [temp, setTemp] = useState(Companies);
  const options = [
    { label: "All Members", value: 3 },
    { label: "Schools", value: 0 },
    { label: "Foundation", value: 1 },
    { label: "Affialite", value: 2 },
  ];

  const onChange = (a) => {
    setRadio(a.target.value);
    if (a.target.value === 3) {
      setTemp(Companies);
    } else {
      setTemp(Companies.filter((c) => c.type === a.target.value));
    }
  };

  const cardClicked = (c) => {
    setActiveCompany(c);
    setModalOpen(true);
  };
  return (
    <div className="container mx-auto py-5 max-w-6xl">
      <Modal
        visible={modalOpen}
        closable
        style={{ top: 20 }}
        onCancel={() => setModalOpen(false)}
        footer={null}
      >
        <>
          {activeCompany && (
            <Descriptions
              title={activeCompany.name}
              bordered
              size="small"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item label="Type">
                {activeCompany.type === 0
                  ? "School Member"
                  : activeCompany.type === 1
                  ? "Foundation Member"
                  : "Affialite Member"}
              </Descriptions.Item>
              <Descriptions.Item label="State">
                {activeCompany.state}
              </Descriptions.Item>
              <Descriptions.Item label="Established">
                {activeCompany.establish}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {activeCompany.address}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                <a href={"tel://" + activeCompany.tel}>{activeCompany.tel}</a>
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                <a href={"mailto://" + activeCompany.email}>
                  {activeCompany.email}
                </a>
              </Descriptions.Item>
              <Descriptions.Item label="Web">
                <Link
                  to={{ pathname: "http://" + activeCompany.web }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {activeCompany.web}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Twitter">
                <Link
                  to={{
                    pathname: "http://twitter.com/" + activeCompany.twitter,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @{activeCompany.twitter}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Facebook">
                <Link
                  to={{
                    pathname: "http://facebook.com/" + activeCompany.facebook,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {activeCompany.facebook}
                </Link>{" "}
              </Descriptions.Item>
              {activeCompany.type === 0 && (
                <>
                  <Descriptions.Item label="Classes">
                    {activeCompany.classes}
                  </Descriptions.Item>
                  <Descriptions.Item label="Students">
                    {activeCompany.students}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campuses">
                    {activeCompany.campuses}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          )}
          <br />
          <Button block type="primary" onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </>
      </Modal>
      <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl text-center">
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-blue-400">
          Our Members
        </h2>
        <p className="text-lg text-gray-500">
          Click to card for member details
        </p>
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <Radio.Group
          options={options}
          onChange={onChange}
          value={radio}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
      <br />
      <div style={{ backgroundColor: "#fafafa" }} className="p-4 border">
        <Row gutter={[16, 16]} style={{ height: "100%" }}>
          {temp.map((c, index) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} key={c.logo}>
              <Card
                onClick={() => cardClicked(c)}
                style={{
                  border: "1px solid #ddd",
                  textAlign: "center",
                  color: "#246BBA",
                  fontWeight: 600,
                }}
                hoverable
                cover={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require(`../../assets/images/${c.logo}`).default}
                      alt=""
                      style={{ maxWidth: "130px", textAlign: "center" }}
                    />
                  </div>
                }
              >
                {c.name.toUpperCase()}
                <p className="text-blue-400">{c.state}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Members;
