import {
  PlusOutlined,
  MinusCircleOutlined,
  SaveOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Switch,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { AdminService } from "../../../resources/AdminService";
import { GlobalVariables } from "../../../resources/GlobalVariables";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const NewNewsForm = ({ sir, newPanel }) => {
  const [form] = useForm();
  const [quillValue, setQuillValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const { lg } = useBreakpoint();

  const onFinish = (values) => {
    if (fileList.length === 0) {
      notification.error({
        message: "Error!",
        description: "You must be save banner image",
      });
      return;
    }
    if (quillValue === "") {
      notification.error({
        message: "Error!",
        description: "Please type news details",
      });
      return;
    }

    const imajlar = [];
    imajlar.push(fileList[0].response.url);
    if (fileList2.length > 0) {
      fileList2.forEach((i) => {
        imajlar.push(i.response.url);
      });
    }

    const req = {
      description: values.short,
      title: values.title,
      details: quillValue,
      isActive: true,
      isFavorite: values.isFavorite,
      images: imajlar,
      videos: values.videos,
    };
    AdminService.newNews(req).then((data) => {
      notification.success({
        message: "Success!",
        description: "News added successfully",
      });
      newPanel(false);
      sir(true);
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setFileList2([]);
    quillChange("");
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  const switchChange = (a) => {
    setIsFavorite(a);
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 18 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 18, offset: 6 },
    },
  };
  return (
    <div>
      <Form
        layout={!lg ? "vertical" : "horizontal"}
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        initialValues={{ videos: [], isFavorite: false }}
        {...GlobalVariables.formLayout}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: "Title required" }]}
        >
          <Input placeholder="Pleace type title" />
        </Form.Item>
        <Form.Item
          label="Short Description"
          name="short"
          rules={[{ required: true, message: "Short description required" }]}
        >
          <Input placeholder="Pleace type short description" />
        </Form.Item>
        <Form.Item
          label="Details"
          rules={[{ required: true, message: "Details required" }]}
        >
          <ReactQuill
            modules={modules}
            theme="snow"
            onChange={(e) => quillChange(e)}
            placeholder="Type news long details"
          />
        </Form.Item>
        <Form.Item label="Banner Image (required)">
          <div className="clearfix">
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreview}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
          <small>Aspect Ratio must be 4:3</small>
        </Form.Item>
        <Form.Item label="Gallery Images (optional)">
          <>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList2}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </Form.Item>
        <Form.List name="videos">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Video Links" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input video link",
                      },
                      { type: "url", message: "Invalid url" },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="Paste link here"
                      style={{ width: "60%" }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item {...GlobalVariables.tailLayout}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add Video Link (optional)
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item label="Is Favorite ?" name="isFavorite">
          <Switch checked={isFavorite} onChange={switchChange} />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              Save
            </Button>
            <Button htmlType="reset" icon={<ClearOutlined />}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewNewsForm;
