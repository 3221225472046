import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Image, Row } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import { useHistory, useLocation } from "react-router-dom";

const NewsDetails = () => {
  // @ts-ignore
  const location = useLocation();
  const router = useHistory();
  function createMarkup(gelenDetay) {
    return {
      __html: gelenDetay,
    };
  }
  // @ts-ignore
  const { veri } = location.state || { veri: null };
  return (
    <div className="overflow-hidden container mx-auto max-w-5xl px-2 py-10">
      <Button
        onClick={() => router.goBack()}
        icon={<ArrowLeftOutlined />}
        type="primary"
        ghost
      >
        Go Back
      </Button>
      <br />
      <br />
      <p className="font-extrabold text-2xl text-blue-500">{veri.news.title}</p>
      <p className="text-lg text-gray-500">{veri.news.description}</p>
      <Image
        preview={false}
        style={{
          maxWidth: "800px",
          border: "3px solid #fff",
          boxShadow: "0 0 5px #eee",
        }}
        src={process.env.REACT_APP_FILES_BASEURL + veri.news.images[0].url}
        alt=""
      />
      <br />
      <br />
      <div dangerouslySetInnerHTML={createMarkup(veri.news.details)} />
      <br />
      {veri.news.images.length > 1 && (
        <div className="max-w-3xl">
          <Divider orientation="left">Image Gallery</Divider>
          <Image.PreviewGroup>
            <Row align="middle" gutter={[10, 10]}>
              {veri.news.images.map((imaj) => (
                <Col key={imaj.id}>
                  <Image
                    className="gallery-image"
                    width={200}
                    src={process.env.REACT_APP_FILES_BASEURL + imaj.url}
                  />
                </Col>
              ))}
            </Row>
          </Image.PreviewGroup>
        </div>
      )}
      {veri.news.videos.length > 0 && (
        <div className="max-w-3xl">
          <Divider orientation="left">Video Gallery</Divider>

          <Row align="middle" gutter={[10, 10]}>
            {veri.news.videos.map((video) => (
              <Col
                key={video.id}
                style={{
                  width: "100%",
                }}
              >
                <div className="player-wrapper">
                  <ReactPlayer
                    url={video.url}
                    className="react-player"
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default NewsDetails;
