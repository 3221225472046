import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../resources/AdminService";

const Newsletters = () => {
  const [newsletters, setNewsletters] = useState(null);

  useEffect(() => {
    AdminService.getNewsletters().then((data) => {
      setNewsletters(data.data);
    });
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "veri",
      render: (veri) => veri.name,
    },
    { title: "Email", dataIndex: "veri", render: (veri) => veri.email },
    {
      title: "Date",
      dataIndex: "veri",
      render: (veri) => moment(veri.createdOn).format("DD-MM-YY ddd HH:mm"),
    },
  ];
  return (
    <div className="max-w-4xl">
      <p className="text-blue-500 text-3xl font-extrabold">
        Newsletter Subscriptions
      </p>
      {newsletters && (
        <Table
          style={{ border: "1px solid #ddd" }}
          scroll={{ x: 600 }}
          dataSource={newsletters}
          columns={columns}
          bordered
          size="small"
          footer={() => newsletters.length + " subscriptions"}
        />
      )}
    </div>
  );
};

export default Newsletters;
