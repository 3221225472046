import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import {
  TicketIcon,
  UserGroupIcon,
  QrcodeIcon,
} from "@heroicons/react/outline";
import SliderBar from "./SliderBar";
import SliderTest from "./SliderTest";
import { Link } from "react-router-dom";
const HeroSection = () => {
  const { lg } = useBreakpoint();
  const supportLinks = [
    {
      name: "About Us",
      href: "/home/about",
      description:
        "Australian Universal Federation of Education and Culture (AUF)’s mission is to provide professional advice, services and leadership for its members.",
      icon: UserGroupIcon,
    },
    {
      name: "Events",
      href: "/home/iflc",
      description:
        "Australian Universal Federation leads many local and global events, especially in the field of education and international culture.",
      icon: TicketIcon,
    },
    {
      name: "Legal Resources",
      href: "#",
      description:
        "Access the database of the school related policies and regulations through our member institutions.",
      icon: QrcodeIcon,
    },
  ];
  return (
    <div>
      {/* Hero Section */}
      <div className="relative mt-2">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-blue-900" />
        <div
          className=" mx-auto sm:px-6 lg:px-8"
          style={{ maxWidth: "1300px" }}
        >
          <SliderTest />
        </div>
      </div>
      <div className="bg-white">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div
            className="absolute inset-0"
            style={{ backgroundColor: "white" }}
          >
            <div
              className="absolute inset-0 bg-blue-900"
              aria-hidden="true"
              style={{
                borderRadius: lg ? "0 0 200px 200px" : "",
              }}
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-5xl">
              Members
            </h1>
            <br />
            <SliderBar />
            <p className="mt-6 max-w-7xl text-xl text-gray-300 text-center">
              Australian Universal Federation has 10 main members which consist
              of 5 schools and their founding entities in NSW, VIC, SA, WA and
              QLD. We also have many project partners and affiliated members
              across the nation.
            </p>
            <br />
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-40 max-w-7xl mx-auto relative z-10 pb-20 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Contact us
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {supportLinks.map((link) => (
              <div
                key={link.name}
                className="flex flex-col bg-white rounded-2xl shadow-xl border"
              >
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-blue-400 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
                <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                  <Link
                    to={link.href}
                    className="text-base font-medium text-blue-700 hover:text-blue-400"
                  >
                    Read More<span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroSection;
