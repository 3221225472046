import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";
import FacebookWidget from "./FacebookWidget";
import TwitterWidget from "./TwitterWidget";

const NewsBanner = () => {
  const [n, setN] = useState(null);
  const router = useHistory();
  useEffect(() => {
    AdminService.getFavoriteNews().then((data) => {
      setN(data.data);
    });
  }, []);
  const goDetails = (verim) => {
    const veri = { key: verim.id, news: verim };
    router.push(`/home/news/${verim.id}/details`, { veri });
  };
  return (
    <div>
      <div className="relative bg-blue-900 pt-16 pb-20 px-4 sm:px-6 lg:pt-10 lg:pb-16 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-gray-50 h-1/3 sm:h-2/3 border-t" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-extrabold text-blue-400 sm:text-4xl">
              Latest News & Social Media Posts
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Latest news and posts about AUF activities and announcements
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              {n && (
                <>
                  <div className="flex-shrink-0">
                    <img
                      className="h-56 w-full object-cover"
                      src={
                        process.env.REACT_APP_FILES_BASEURL + n.images[0].url
                      }
                      alt=""
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-indigo-600">
                        {moment(n.createdOn).format("DD-MM-YY dddd")}
                      </p>

                      <p className="text-xl font-semibold text-gray-900">
                        {n.title}
                      </p>
                      <p className="mt-3 text-base text-gray-500">
                        {n.description}
                      </p>
                    </div>
                    <div className="mt-6 flex items-center">
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => goDetails(n)}
                      >
                        Read More
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px 8px 8px 15px",
                    width: "100%",

                    backgroundColor: "white",
                    border: "1px solid #ddd",
                    borderRadius: "8px 8px 0 0 ",
                  }}
                >
                  <span style={{ fontSize: "18px", lineHeight: "30px" }}>
                    From Facebook
                  </span>
                </div>
                <FacebookWidget />
              </div>
            </div>
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <TwitterWidget />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsBanner;
