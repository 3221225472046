import Homepage from "../components/home/Homepage";
import { MenuOutlined } from "@ant-design/icons";
import { Layout, Row, Col, Button, Drawer } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FooterWidget from "../components/home/FooterWidget";
import MainMenu from "./MainMenu";
import AboutAuf from "../components/about/AboutAUF";
import OrgChart from "../components/about/OrgChart";
import AnnualReports from "../components/about/AnnualReports";
import Members from "../components/members/Members";
import Iflc from "../components/events/iflc";
import ContactForm from "../components/contact/ContactForm";
import NewsPage from "../components/home/NewsPage";
import NewsDetails from "../components/staff/news/NewsDetails";

const HomeLayout = () => {
  const { Header, Content, Footer } = Layout;
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Layout className="layout">
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 33,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <div className="container mx-auto max-w-6xl">
          <Row justify="space-between">
            <Col>
              <div className="logo" />
            </Col>
            <Col>
              {lg ? (
                <MainMenu sic={setIsCollapsed} />
              ) : (
                <Button
                  icon={<MenuOutlined />}
                  size="large"
                  onClick={() => setIsCollapsed(true)}
                />
              )}
            </Col>
          </Row>
        </div>
      </Header>

      <Content style={{ padding: "0", marginTop: lg ? "75px" : "unset" }}>
        <Drawer
          title={
            <img
              src={require("../assets/images/logo.png").default}
              width="200"
              alt="logo"
            />
          }
          visible={isCollapsed}
          placement="left"
          closable={true}
          onClose={() => setIsCollapsed(false)}
        >
          <MainMenu sic={setIsCollapsed} />
        </Drawer>
        <div className="site-layout-content">
          <Switch>
            <Route path="/home" exact>
              <Redirect to="/home/homepage" />
            </Route>
            <Route path="/home/homepage" component={Homepage} />
            <Route path="/home/about" component={AboutAuf} />
            <Route path="/home/chart" component={OrgChart} />
            <Route path="/home/members" component={Members} />
            <Route path="/home/iflc" component={Iflc} />
            <Route path="/home/news/:id/details" component={NewsDetails} />
            <Route path="/home/news" component={NewsPage} />
            <Route path="/home/contact" component={ContactForm} />
            <Route path="/home/reports" component={AnnualReports} />
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </div>
      </Content>
      <Footer style={{ padding: 0 }}>
        <FooterWidget />
      </Footer>
    </Layout>
  );
};

export default HomeLayout;
