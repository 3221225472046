import { FileSearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Image, Row, Skeleton } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const NewsPage = () => {
  const [newsler, setNewsler] = useState(null);
  const router = useHistory();
  useEffect(() => {
    AdminService.getNewsHome().then((data) => {
      setNewsler(data.data);
    });
  }, []);
  const goDetails = (veri) => {
    router.push(`/home/news/${veri.key}/details`, { veri });
  };
  return (
    <div className="container mx-auto max-w-6xl p-2">
      <br />
      <div className="text-center">
        <h2 className="text-3xl tracking-tight font-extrabold text-blue-400 sm:text-4xl">
          News from AUF
        </h2>
        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
          Latest news and notifications about AUF activities and announcements
        </p>
      </div>
      <Row gutter={[16, 16]}>
        {newsler &&
          newsler.map((n, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={8}
              xl={8}
              style={{
                display: "inline-flex",
                alignSelf: "stretch",
              }}
            >
              <Card
                cover={
                  <Image
                    preview={false}
                    alt="example"
                    src={
                      process.env.REACT_APP_FILES_BASEURL + n.news.images[0].url
                    }
                  />
                }
                className="kart"
                style={{
                  border: "1px solid #ddd",
                  boxShadow: "0 0 10px #ddd",
                  transition: "all ease 200ms",
                  paddingBottom: "45px",
                  width: "100%",
                }}
                actions={[
                  <Button
                    icon={<FileSearchOutlined />}
                    type="link"
                    onClick={() => goDetails(n)}
                  >
                    Details
                  </Button>,
                ]}
              >
                <Meta
                  title={
                    <div>
                      <small className="text-blue-500">
                        {moment(n.news.createdOn).format("DD MMM YYYY dddd")}
                      </small>
                      <p>{n.news.title}</p>
                    </div>
                  }
                  description={n.news.description}
                />
              </Card>
            </Col>
          ))}
        {!newsler && (
          <Skeleton loading={true} avatar active>
            <Meta
              avatar={
                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              }
              title="Card title"
              description="This is the description"
            />
          </Skeleton>
        )}
      </Row>
      <br />

      <br />
    </div>
  );
};

export default NewsPage;
