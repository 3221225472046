import { Row, Col, Card, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const Staffpage = () => {
  const [reports, setReports] = useState(null);
  const router = useHistory();
  useEffect(() => {
    AdminService.getDashboard().then((data) => {
      setReports(data.data);
    });
  }, []);
  return (
    <div>
      {reports && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "15px",
            border: "1px solid #ddd",
          }}
        >
          <p className="text-blue-500 text-3xl font-extrabold">Dashboard</p>
          <Row gutter={[6, 6]} wrap>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/staff/contacts")}
              >
                <Statistic
                  title="
            Contacts"
                  value={reports.contacts}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/staff/contacts")}
              >
                <Statistic
                  title="Unread"
                  value={reports.unread}
                  valueStyle={{
                    fontWeight: "bold",
                    color: reports.unread === 0 ? "green" : "coral",
                  }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/staff/news")}
              >
                <Statistic
                  title="
            News"
                  value={reports.news}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="stat-card"
                hoverable
                onClick={() => router.push("/staff/newsletters")}
              >
                <Statistic
                  title="Newsletters"
                  value={reports.newsletters}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="
            Images"
                  value={reports.images}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card className="stat-card" hoverable>
                <Statistic
                  title="Videos"
                  value={reports.videos}
                  valueStyle={{ fontWeight: "bold" }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Staffpage;
