import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import Slider from "react-slick";

const SliderTest = () => {
  const { lg } = useBreakpoint();
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="container mx-auto">
      <Slider
        {...settings}
        // @ts-ignore
        style={{
          border: lg ? "5px solid white" : "",
          borderRadius: lg ? "20px" : "",
          overflow: lg ? "hidden" : "",
        }}
      >
        {lg && (
          <div className="slayt">
            <div className="layer" />
            <div className="ic" style={{ top: lg ? "50%" : "30%" }}>
              <p className=" text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white mb-0">
                Advance cooperation <br />
                <b className="text-blue-300">Professional development</b>
              </p>
              <p className="mt-2 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                AUF was founded in 2012 by the following foundations in 5
                different states in order to advance cooperation and
                professional development activities between its members.
              </p>
              {/* <Button size="large" type="primary">
              Lets Go
            </Button> */}
            </div>
          </div>
        )}

        <div className="slayt2">
          <div className="layer" />
          <div className="ic" style={{ top: lg ? "50%" : "45%" }}>
            <p className=" text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white mb-0">
              Professional Learning
            </p>
            <p className="mt-2 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
              AUF supports members to increase their education quality through
              professional training activities.
            </p>
            {/* <Button size="large" type="primary">
              Lets Go
            </Button> */}
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderTest;
