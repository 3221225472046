export const Companies = [
  {
    type: 0,
    name: "Amitiy College",
    logo: "a3.png",
    state: "NSW",
    establish: "1996",
    address: "163 Kurrajong Rd Prestons 2170",
    tel: "(02) 8784 3111",
    web: "www.amity.nsw.edu.au",
    email: "info@amity.nsw.edu.au",
    twitter: "amity_college",
    facebook: "AmityCollegeAU",
    classes: "K-12",
    students: "1800+",
    campuses: "3 Campuses",
  },
  {
    type: 0,
    name: "Sirius College",
    logo: "a1.png",
    state: "VIC",
    establish: "1997",
    address: "Goulburn Street Broadmeadows 3047",
    tel: "(03) 8576 8500",
    web: "www.sirius.vic.edu.au",
    email: "info@sirius.vic.edu.au",
    twitter: "siriuscollege",
    facebook: "SiriusCollegeAU",
    classes: "K-12",
    students: "2500+",
    campuses: "6 Campuses",
  },
  {
    type: 0,
    name: "Wisdom College",
    logo: "a5.png",
    state: "QLD",
    establish: "2012",
    address: "97 Formby Street Calamvale 4112",
    tel: "(07) 37119821",
    web: "www.wisdom.qld.edu.au",
    email: "info@wisdom.qld.edu.au",
    twitter: "WisdomCollege",
    facebook: "WisdomCollegeAU",
    classes: "K-7",
    students: "150+",
    campuses: "1 Campus",
  },
  {
    type: 0,
    name: "Pinnacle College",
    logo: "a7.png",
    state: "SA",
    establish: "2005",
    address: "10 Anderson Street Elizabeth East 5112",
    tel: "(08) 8367 9022",
    web: "www.pinnacle.sa.edu.au",
    email: "info@pinnacle.sa.edu.au",
    twitter: "PinnacleCollege",
    facebook: "PinnacleCollegeAU",
    classes: "K-12",
    students: "500+",
    campuses: "2 Campuses",
  },
  {
    type: 0,
    name: "Fountain College",
    logo: "a9.png",
    state: "WA",
    establish: "2005",
    address: "12 Karri Way Ferndale 6148",
    tel: "(08) 9458 3555",
    web: "www.fountain.wa.edu.au",
    email: "admin@fountain.wa.edu.au",
    twitter: "Fountain_Perth",
    facebook: "fountaincollege",
    classes: "K-12",
    students: "200+",
    campuses: "2 Campuses",
  },
  {
    type: 1,
    name: "Galaxy Foundation",
    logo: "a4.png",
    state: "NSW",
    establish: "1994",
    address: "L 1, 27 Queen St Auburn 2144",
    tel: "(02) 9649 3494",
    web: "wwww.galaxyfoundation.org.au",
    email: "info@galaxyfoundation.org.au",
    twitter: "",
    facebook: "",
    classes: "",
    students: "",
    campuses: "",
  },
  {
    type: 1,
    name: "Selimiye Foundation",
    logo: "a2.png",
    state: "VIC",
    establish: "1992",
    address: "22-24 Lismore Street Dallas, 3047",
    tel: "(03) 9309 6168",
    web: "www.selimiye.com.au",
    email: "info@selimiye.com.au",
    twitter: "",
    facebook: "",
    classes: "",
    students: "",
    campuses: "",
  },
  {
    type: 1,
    name: "Queensland E.C.F.",
    logo: "a6.png",
    state: "QLD",
    establish: "2004",
    address: "97 Formby Street Calamvale 4112",
    tel: "(07) 37119821",
    web: "www.qecf.org.au",
    email: "info@wisdom.qld.edu.au",
    twitter: "",
    facebook: "",
    classes: "",
    students: "",
    campuses: "",
  },
  {
    type: 1,
    name: "McYess Ltd",
    logo: "a8.png",
    state: "SA",
    establish: "2005",
    address: "10 Anderson Street Elizabeth East 5112",
    tel: "(08) 8367 9022",
    web: "www.mcyess.org.au",
    email: "info@mcyess.org.au",
    twitter: "",
    facebook: "",
    classes: "",
    students: "",
    campuses: "",
  },
  {
    type: 1,
    name: "Baris Foundation",
    logo: "a10.png",
    state: "WA",
    establish: "2005",
    address: "12 Karri Way Ferndale 6148",
    tel: "(08) 9458 3555",
    web: "www.barisfoundation.org.au",
    email: "info@barisfoundation.org.au",
    twitter: "",
    facebook: "",
    classes: "",
    students: "",
    campuses: "",
  },
  //   {
  //     type: 2,
  //     name: "",
  //     logo: "",
  //     state: "",
  //     establish: "",
  //     address: "",
  //     tel: "",
  //     web: "",
  //     email: "",
  //     twitter: "",
  //     facebook: "",
  //     classes: "",
  //     students: "",
  //     campuses: "",
  //   },
  //   {
  //     type: 1,
  //     name: "",
  //     logo: "",
  //     state: "",
  //     establish: "",
  //     address: "",
  //     tel: "",
  //     web: "",
  //     email: "",
  //     twitter: "",
  //     facebook: "",
  //     classes: "",
  //     students: "",
  //     campuses: "",
  //   },
];
