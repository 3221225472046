import Slider from "react-slick";
import React, { Component } from "react";

export default class SliderBar extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      autoplay: true,
      speed: 8000,
      arrows: false,
      autoplaySpeed: 0,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    return (
      <div style={{ maxWidth: "100%" }}>
        <Slider {...settings}>
          <div className="justify-center align-middle text-center mx-auto">
            <img
              className="self-center"
              src={require("../../assets/images/l1.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center mx-auto">
            <img
              className="self-center"
              src={require("../../assets/images/l2.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center mx-auto">
            <img
              className="self-center"
              src={require("../../assets/images/l3.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l4.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l5.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l6.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l7.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l8.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l9.png").default}
              alt="member"
            />
          </div>

          <div className="justify-center align-middle text-center">
            <img
              className="self-center"
              src={require("../../assets/images/l10.png").default}
              alt="member"
            />
          </div>
        </Slider>
      </div>
    );
  }
}
