import { Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import ReactPlayer from "react-player";

const YouDebate = () => {
  const { sm } = useBreakpoint();
  return (
    <div
      className="container mx-auto max-w-7xl"
      style={{ marginBottom: sm ? "40px" : "" }}
    >
      <br />
      <br />
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <img
            src={require("../../assets/images/banner2.jpg").default}
            alt="youdebate"
            width="100%"
            style={{
              border: "3px solid white",
              boxShadow: "0 0 5px #ddd",
              borderRadius: "10px",
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <ReactPlayer
            url="https://vimeo.com/263277914"
            width="100%"
            style={{ margin: !sm ? "-60px 0 0 0" : "" }}
            controls
          />
        </Col>
      </Row>
    </div>
  );
};

export default YouDebate;
