import React, { useEffect, useState } from "react";
import { Button, Col, Drawer, Layout, Menu, Row, Tag } from "antd";
import {
  DashboardOutlined,
  FormOutlined,
  HeartOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";
import Staffpage from "../components/staff/Staffpage";
import MainMenu from "./MainMenu";
import Contacts from "../components/staff/Contacts";
import Newsletters from "../components/staff/Newsletters";
import News from "../components/staff/news/News";
import NewsDetails from "../components/staff/news/NewsDetails";
import Forms from "../components/staff/forms/Forms";

const StaffLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSider, setIsSider] = useState(false);
  const location = useLocation();
  const router = useHistory();

  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify="space-between" align="middle">
          {lg ? undefined : (
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          )}
          <Col>
            <div className="logo" style={{ maxWidth: !lg && "100px" }} />
            {lg && <MainMenu sic={setIsCollapsed} />}
          </Col>
          <Col>
            {lg ? (
              <Button
                onClick={() => {
                  localStorage.clear();
                  router.push("/home");
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            ) : (
              <Button
                icon={<MenuOutlined />}
                size="large"
                onClick={() => setIsCollapsed(true)}
              />
            )}
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Admin Menu
          </Tag>
          <Menu
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
            style={{ backgroundColor: "transparent" }}
          >
            <Menu.Item key="staffpage" icon={<DashboardOutlined />}>
              <Link to="/staff/staffpage">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="contacts" icon={<MessageOutlined />}>
              <Link to="/staff/contacts">Contacts</Link>
            </Menu.Item>
            <Menu.Item key="newsletters" icon={<MailOutlined />}>
              <Link to="/staff/newsletters">Newsletters</Link>
            </Menu.Item>
            <Menu.Item key="news" icon={<NotificationOutlined />}>
              <Link to="/staff/news">News</Link>
            </Menu.Item>
            <Menu.Item key="forms" icon={<FormOutlined />}>
              <Link to="/staff/forms">Forms</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Drawer
            title={
              <img
                src={require("../assets/images/logo.png").default}
                width="150"
                alt="logo"
              />
            }
            visible={isCollapsed}
            placement="right"
            closable={true}
            onClose={() => setIsCollapsed(false)}
          >
            <MainMenu sic={setIsCollapsed} />
          </Drawer>
          <Switch>
            <Route path="/staff" exact>
              <Redirect to="/staff/staffpage" />
            </Route>
            <Route path="/staff/staffpage" component={Staffpage} />
            <Route path="/staff/contacts" component={Contacts} />
            <Route path="/staff/newsletters" component={Newsletters} />
            <Route path="/staff/forms" component={Forms} />
            <Route path="/staff/news/:id/details" component={NewsDetails} />
            <Route path="/staff/news" component={News} />
            <Route>
              <Redirect to="/404" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            &copy; 2021 The Australian Universal Federation . All rights
            reserved. <br />
            Developed by{" "}
            <Link
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default StaffLayout;
