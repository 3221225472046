import {
  EnvironmentOutlined,
  HeartOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { Link } from "react-router-dom";
import { Follow } from "react-twitter-widgets";
import { AdminService } from "../../resources/AdminService";

const FooterWidget = () => {
  const [form] = useForm();
  const onFinish = (v) => {
    AdminService.newNewsletter(v).then((data) => {
      notification.success({
        message: "Success!",
        description: "Thank you for subscription",
      });
      form.resetFields();
    });
  };

  return (
    <div>
      <footer className=" p-8 bg-blue-900 " aria-labelledby="footer-heading">
        <div className="container mx-auto max-w-7xl">
          <Row gutter={[24, 54]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="space-y-8 xl:col-span-1">
                <img
                  className="h-16"
                  src={require("../../assets/images/logo-white.png").default}
                  alt="footer"
                />
                <p className="text-gray-200 text-base">
                  Follow us on social media
                </p>
                <Space direction="vertical">
                  <div>
                    <Follow username="AusFed" options={{ size: "large" }} />
                  </div>
                  <div
                    style={{ marginLeft: "-10px" }}
                    className="fb-like"
                    data-href="https://www.facebook.com/ausfed"
                    data-width="200"
                    data-layout="button_count"
                    data-action="like"
                    data-size="large"
                    data-share="true"
                  ></div>
                </Space>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div>
                <h3
                  style={{
                    color: "whitesmoke",
                    borderBottom: "1px solid rgba(118, 172, 218, .2)",
                    marginBottom: "25px",
                    lineHeight: "44px",
                  }}
                >
                  CONTACT DETAILS
                </h3>
                <p style={{ color: "whitesmoke", fontSize: "17px" }}>
                  <EnvironmentOutlined
                    style={{ fontSize: "24px", color: "#8AC1FF" }}
                  />
                  &nbsp; &nbsp; 7 Speed Street Liverpool NSW 2170
                </p>
                <p
                  style={{
                    color: "#8AC1FF",
                    fontWeight: "lighter",
                    fontSize: "24px",
                  }}
                >
                  <PhoneOutlined style={{ fontSize: "24px" }} />
                  <a href="tel://0280658469" style={{ color: "white" }}>
                    &nbsp; 02 9822 4448
                  </a>
                </p>
                <p style={{ color: "#8AC1FF", fontSize: "18px" }}>
                  <MailOutlined style={{ fontSize: "24px" }} />
                  <a href="mailto://info@aro.org.au" style={{ color: "white" }}>
                    &nbsp; info@auf.net.au
                  </a>
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <h3
                style={{
                  color: "whitesmoke",
                  borderBottom: "1px solid rgba(118, 172, 218, .2)",
                  marginBottom: "25px",
                  lineHeight: "44px",
                }}
              >
                SUBSCRIBE TO OUR NEWSLETTER
              </h3>
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Fullname required" }]}
                >
                  <Input
                    placeholder="Enter your fullname"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email required" },
                    { type: "email" },
                  ]}
                >
                  <Input
                    placeholder="Enter your email address"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" size="large">
                    SUBSCRIBE
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <div
          className="mt-6 border-t  pt-8"
          style={{ borderColor: "rgba(118, 172, 218, .2)" }}
        >
          <p className="text-small text-gray-400 text-center">
            &copy; 2021 The Australian Universal Federation . All rights
            reserved. <br />
            Developed by{" "}
            <Link
              style={{ color: "lightgray" }}
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </footer>
    </div>
  );
};

export default FooterWidget;
