import { Button } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { useHistory } from "react-router-dom";
const IflcSection = () => {
  const { sm } = useBreakpoint();
  const router = useHistory();
  return (
    <div>
      <img
        src={
          require(sm
            ? "../../assets/images/iflc2.jpg"
            : "../../assets/images/miflc.jpg").default
        }
        alt="iflc"
        width="100%"
        style={{ border: sm ? "1px solid #eee" : "" }}
      />

      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-10 items-center justify-center flex-col">
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-700">
              International Festival of Language and Culture (IFLC)
            </h1>
            <p className="mb-8 leading-relaxed">
              In 2015 this spectacular festival was performed in Australia for
              the first time, with our member Sirius College’s organisation at
              Melbourne Convention Centre. Thereon, the festival has been hosted
              between Melbourne and our Sydney members Amity College and
              Affinity Intercultural Foundation.
            </p>
            <div className="flex justify-center">
              <Button
                size="large"
                type="primary"
                onClick={() => router.push("/home/iflc")}
              >
                Click For Details
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IflcSection;
