import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import "nprogress/nprogress.css";
import { axHistory } from "./resources/axRouter";
import ScrollToTop from "./resources/scrollToTop";

ReactDOM.render(
  <React.StrictMode>
    <Router history={axHistory}>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
