import { Button } from "antd";
import React from "react";

const AnnualReports = () => {
  return (
    <div className="max-w-6xl container mx-auto">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              AUF Annual Activity Reports
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              You can download our annual activity reports from the links below
            </p>
          </div>

          <div className="flex flex-wrap -m-4">
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute imaj  inset-0 w-full h-full object-cover object-right-bottom"
                  src={require("../../assets/images/2015.jpg").default}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    ANNUAL ACTIVITY REPORT
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    January - December 2015
                  </h1>
                  <br />{" "}
                  <Button block type="primary">
                    Click For Download
                  </Button>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute imaj  inset-0 w-full h-full object-cover object-right-bottom"
                  src={require("../../assets/images/2016.jpg").default}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    ANNUAL ACTIVITY REPORT
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    January - December 2016
                  </h1>
                  <br />{" "}
                  <Button block type="primary">
                    Click For Download
                  </Button>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute imaj  inset-0 w-full h-full object-cover object-right-bottom"
                  src={require("../../assets/images/2017.jpg").default}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    ANNUAL ACTIVITY REPORT
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    January - December 2017
                  </h1>
                  <br />{" "}
                  <Button block type="primary">
                    Click For Download
                  </Button>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 sm:w-1/2 p-4">
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute imaj  inset-0 w-full h-full object-cover object-right-bottom"
                  src={require("../../assets/images/2018.jpg").default}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                  <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                    ANNUAL ACTIVITY REPORT
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    January - December 2018
                  </h1>
                  <br />{" "}
                  <Button block type="primary">
                    Click For Download
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AnnualReports;
